import React from "react";
import { Destination } from "@prequel/react";
import { FormField } from "@prequel-internal/react-components";

type BaseFormProps = {
  isEditing: boolean;
  displayName: string;
  destination: Destination;
  setDestinationField: (key: keyof Destination, value: string) => void;
  disabled?: boolean;
};
const BaseForm = ({
  isEditing,
  displayName,
  destination,
  setDestinationField,
  disabled = false,
}: BaseFormProps) => {
  return (
    <div>
      <label
        htmlFor="description"
        className="block text-sm font-medium text-gray-700"
      >
        {`Name and identify the ${displayName} destination `}
      </label>
      <div className="mt-1 mb-4">
        <p className="mt-1 text-sm text-gray-500">
          {`Please be particularly careful entering the "ID in Provider System" —
          this is the key by which data will be filtered, and an incorrect entry
          could send the wrong data to the destination.`}
        </p>
      </div>
      <div className="space-y-4">
        <FormField
          label="Name the destination"
          id="name"
          type="text"
          subtext={`Descriptive name for this destination. This will only be visible internally and is only used as a reference. (e.g., Acme Corp ${displayName})`}
          value={destination?.name}
          onChangeHandler={(value: string) => {
            setDestinationField("name", value);
          }}
          required
          disabled={disabled}
        />
        <FormField
          label="ID in Provider System"
          id="id_in_provider_system"
          type="text"
          subtext="Customer ID by which the source data will be filtered. In other words, this is the ID of the target customer in your source database. An engineer or database administrator can provide the unique value for each destination."
          value={destination?.id_in_provider_system}
          onChangeHandler={(value: string) => {
            setDestinationField("id_in_provider_system", value);
          }}
          required
          disabled={disabled || isEditing}
        />
      </div>
    </div>
  );
};

export default BaseForm;
