import {
  Destination,
  VendorField,
  DestinationVendor,
  PreparedDestination,
} from "@prequel/react";
import { formatDistanceToNow, parseJSON } from "date-fns";
import { DropdownListItem } from "@prequel-internal/react-components";

import { Transfer } from "../transfers";

export type ServiceAccountKey = {
  type: string;
  project_id: string;
  private_key_id: string;
  client_email: string;
  client_id: string;
  auth_url: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};

export type DropdownListItemWithFields = DropdownListItem<string> & {
  fields: VendorField[];
  docs: string;
  uses_staging_bucket: boolean;
  uses_service_account: boolean;
  supports_ssh_tunnel: boolean;
};

export type DestinationTestStatus = "processing" | "success" | "error";
export type DestinationTest = {
  status: DestinationTestStatus | undefined;
  message?: string;
};

export type ExistingDestinationTestPayload = {
  destinationId: ExistingDestination["id"];
  fields?: Partial<PreparedDestination>;
};

export const getReadableLastSuccessfulSync = (
  lastSync?: Transfer["ended_at"]
) => {
  if (!lastSync) {
    return "Has not transferred successfully";
  }

  const lastSyncDate = parseJSON(lastSync);
  return formatDistanceToNow(lastSyncDate, { addSuffix: true });
};

export const sortByLastSuccessfulSync = (
  destA: ExistingDestination,
  destB: ExistingDestination
) => {
  const nullDate = new Date(0).toISOString(); // Create nullDate to replace null values with an early timestamp (epoch time)
  const dateA = parseJSON(destA.last_completed_transfer?.ended_at ?? nullDate);
  const dateB = parseJSON(destB.last_completed_transfer?.ended_at ?? nullDate);
  if (dateA > dateB) {
    return -1;
  }

  if (dateB > dateA) {
    return 1;
  }

  return 0;
};

export const getReadableVendor = (
  vendor: string,
  destinationVendors: DestinationVendor[]
) => {
  const destinationVendor = destinationVendors.find(
    ({ vendor_name }) => vendor_name === vendor
  );
  return destinationVendor?.display_name ?? "";
};

// In the unlikely case that the backend returns a destination type that is not supported in the frontend, type the function to return undefined
export const getVendorLogoUrl = (
  vendor: string,
  destinationVendors: DestinationVendor[]
) => {
  const destinationVendor = destinationVendors.find(
    ({ vendor_name }) => vendor_name === vendor
  );
  return destinationVendor?.logo_url ?? "";
};

export default interface ExistingDestination extends PreparedDestination {
  id: string;
  is_enabled: boolean;
  last_successful_transfer_ended_at: string | null;
  last_completed_transfer?: Transfer;
}

export const getValidServiceAccountKey = (
  string?: string
): ServiceAccountKey | undefined => {
  if (!string) {
    return undefined;
  }
  try {
    const token: ServiceAccountKey = JSON.parse(string);
    return token;
  } catch (e) {
    return undefined;
  }
};

export const isExistingDestination = (
  obj: ExistingDestination | PreparedDestination
): obj is ExistingDestination => "id" in obj;

export const getReadableModels = (models: string[] | undefined): string => {
  if (!models || models.length === 0) {
    return "No models";
  }

  if (models.length === 1 && models[0] === "*") {
    return "All available models";
  }

  return models.join(", ");
};

export const convertToDestination: (e: ExistingDestination) => Destination = (
  existing
) => {
  return {
    ...existing,
    password: "",
    service_account_key: JSON.stringify(existing.service_account_key) ?? "",
    frequency_minutes: existing.frequency_minutes
      ? existing.frequency_minutes.toString()
      : "",
    port: existing.port ? existing.port.toString() : "",
    ssh_tunnel_port: existing.ssh_tunnel_port
      ? existing.ssh_tunnel_port.toString()
      : "",
    use_ssh_tunnel: existing.use_ssh_tunnel ?? false,
  };
};

export const computeChangedFields: (
  e: ExistingDestination,
  d: PreparedDestination
) => Partial<PreparedDestination> = (
  existingDestination,
  preparedDestination
) => {
  const changedFields: Partial<PreparedDestination> = {};
  for (const key in existingDestination) {
    if (
      existingDestination[key as keyof ExistingDestination] !==
      preparedDestination[key as keyof PreparedDestination]
    ) {
      Object.assign(changedFields, {
        [key]: preparedDestination[key as keyof PreparedDestination],
      });
    }
  }

  // Special case password because ExistingDestination does not have the password populated
  if (preparedDestination.password && preparedDestination.password !== "") {
    changedFields.password = preparedDestination.password;
  }

  // Special case service_account_key because ExistingDestination does not have service_account_key populated
  if (preparedDestination.service_account_key) {
    changedFields.service_account_key = preparedDestination.service_account_key;
  }

  // Special case frequency_minutes because ExistingDestination may not have it
  if (preparedDestination.frequency_minutes) {
    changedFields.frequency_minutes = preparedDestination.frequency_minutes;
  }

  return changedFields;
};
