import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";

import { AppError } from "../../axios";
import { Transfer } from ".";
import Destination from "../destinations";
import TransfersService from "./transfers.service";
import { RootState, createWorkerSaga } from "..";

import { createToast } from "../toasts/toasts.duck";

// Slice state
type TransfersState = {
  transfers: { [id: Transfer["id"]]: Transfer[] };
  isPostingTransfer: boolean;
};
const initialState: TransfersState = {
  transfers: {},
  isPostingTransfer: false,
};

// Action Reducers (Case Reducers)
const fetchTransfersReducer: CaseReducer<
  TransfersState,
  PayloadAction<{
    destinationId: Destination["id"];
  }>
> = (state) => state;

const fetchTransfersSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<{ destinationId: Destination["id"]; transfers: Transfer[] }>
> = (state, action) => {
  state.transfers[action.payload.destinationId] = action.payload.transfers;
};

const fetchTransfersFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => state;

const createTransferReducer: CaseReducer<
  TransfersState,
  PayloadAction<{ destinationId: Destination["id"]; fullRefresh: boolean }>
> = (state) => {
  state.isPostingTransfer = true;
};

const createTransferSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<void>
> = (state) => {
  state.isPostingTransfer = false;
};

const createTransferFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isPostingTransfer = false;
};

function* watchFetchTransfers() {
  yield takeEvery(
    fetchTransfers.type,
    createWorkerSaga(
      fetchTransfers,
      fetchTransfersSuccess,
      fetchTransfersFailure,
      TransfersService.getTransfers
    )
  );
}

function* watchCreateTransfer() {
  yield takeEvery(
    createTransfer.type,
    createWorkerSaga(
      createTransfer,
      createTransferSuccess,
      createTransferFailure,
      TransfersService.postTransfer
    )
  );
}

function* watchCreateTransferSuccess() {
  yield takeEvery(createTransferSuccess.type, function* () {
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Transfer enqueued successfully!",
        },
      })
    );
  });
}

function* watchCreateTransferSuccessOrFailure() {
  yield takeEvery(
    [createTransferFailure.type, createTransferSuccess.type],
    function* () {
      yield put(fetchTransfers({ destinationId: "" }));
    }
  );
}

const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    fetchTransfers: fetchTransfersReducer,
    fetchTransfersSuccess: fetchTransfersSuccessReducer,
    fetchTransfersFailure: fetchTransfersFailureReducer,
    createTransfer: createTransferReducer,
    createTransferSuccess: createTransferSuccessReducer,
    createTransferFailure: createTransferFailureReducer,
  },
});

export const {
  fetchTransfers,
  fetchTransfersSuccess,
  fetchTransfersFailure,
  createTransfer,
  createTransferSuccess,
  createTransferFailure,
} = transfersSlice.actions;

export const selectTransfers = (destinationId: string) => {
  return ({ transfers }: RootState) => {
    return transfers.transfers[destinationId];
  };
};
export const selectIsPostingTransfer = ({ transfers }: RootState) =>
  transfers.isPostingTransfer;

export function* transfersSaga() {
  yield all([
    watchFetchTransfers(),
    watchCreateTransfer(),
    watchCreateTransferSuccess(),
    watchCreateTransferSuccessOrFailure(),
  ]);
}
export default transfersSlice.reducer;
