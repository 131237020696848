import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
} from "../../axios";

import { Transfer } from ".";
import Destination from "../destinations";

const getTransfers: ({
  destinationId,
}: {
  destinationId: Destination["id"];
}) => Promise<{ destinationId: Destination["id"]; transfers: Transfer[] }> = ({
  destinationId,
}) => {
  return axios
    .get(ApiRoutes.TRANSFERS, {
      params: { count: 20, destination_id: destinationId },
    })
    .then((response: ApiResponse<{ transfers: Transfer[] }>) => ({
      destinationId,
      transfers: response.data.data.transfers,
    }))
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch transfers.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postTransfer: ({
  destinationId,
  fullRefresh,
}: {
  destinationId: Destination["id"];
  fullRefresh: boolean;
}) => Promise<void> = ({ destinationId, fullRefresh }) => {
  return axios
    .post(`${ApiRoutes.DESTINATIONS}/${destinationId}${ApiRoutes.TRANSFER}`, {
      full_refresh: fullRefresh,
    })
    .then((response: ApiResponse<void>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Transfer failed to enqueue.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const TransfersService = {
  getTransfers,
  postTransfer,
};
export default TransfersService;
