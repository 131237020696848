import { Destination, PreparedDestination } from "@prequel/react";
import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
  ID_PLACEHOLDER,
} from "../../axios";

import { WithRedirect } from "..";
import ExistingDestination, {
  DestinationTestStatus,
  ExistingDestinationTestPayload,
} from ".";

const getDestinations: () => Promise<ExistingDestination[]> = () => {
  return axios
    .get(ApiRoutes.DESTINATIONS)
    .then(
      (response: ApiResponse<{ destinations: ExistingDestination[] }>) =>
        response.data.data.destinations
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch destinations.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testDestination: (
  d: PreparedDestination
) => Promise<DestinationTestStatus> = (destination) => {
  return axios
    .post(ApiRoutes.TEST_DESTINATION, { destination })
    .then((response: ApiResponse<DestinationTestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Destination connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testExistingDestination: ({
  destinationId,
  fields,
}: ExistingDestinationTestPayload) => Promise<DestinationTestStatus> = ({
  destinationId,
  fields,
}) => {
  const url = ApiRoutes.TEST_EXISTING_DESTINATION.replace(
    ID_PLACEHOLDER,
    destinationId
  );
  return axios
    .post(url, fields ? { destination: fields } : undefined)
    .then((response: ApiResponse<DestinationTestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Destination connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postDestination: ({
  destination,
  redirect,
}: WithRedirect<{ destination: PreparedDestination }>) => Promise<
  WithRedirect<{ destination: ExistingDestination }>
> = ({ destination, redirect }) => {
  return axios
    .post(ApiRoutes.DESTINATIONS, { destination })
    .then((response: ApiResponse<{ destination: ExistingDestination }>) => ({
      destination: response.data.data.destination,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Destination creation failed.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const transferDestination: ({
  destinationId,
  fullRefresh,
}: {
  destinationId: ExistingDestination["id"];
  fullRefresh: boolean;
}) => Promise<void> = ({ destinationId, fullRefresh }) => {
  return (
    axios
      .post(`${ApiRoutes.DESTINATIONS}/${destinationId}${ApiRoutes.TRANSFER}`, {
        full_refresh: fullRefresh,
      })
      // TODO: capture the transfer_id and trigger a toast to indicate a successful transfer was enqueued
      .then((response: ApiResponse<void>) => response.data.data)
      .catch((reason: ErrorResponse) => {
        const e: AppError = {
          error: {
            message:
              reason.response?.data?.message || "Transfer failed to enqueue.",
            suppressGlobalNotification: false,
            statusCode: reason.response?.status,
          },
        };
        throw e;
      })
  );
};

const patchDestination: ({
  destinationId,
  destination,
}: WithRedirect<{
  destinationId: ExistingDestination["id"];
  destination: Partial<PreparedDestination>;
}>) => Promise<WithRedirect<{ destination: ExistingDestination }>> = ({
  destinationId,
  destination,
  redirect,
}) => {
  return axios
    .patch(`${ApiRoutes.DESTINATIONS}/${destinationId}`, {
      destination: destination,
    })
    .then((response: ApiResponse<{ destination: ExistingDestination }>) => ({
      destination: response.data.data.destination,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to update destination.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const deleteDestination: ({
  destinationId,
  redirect,
}: WithRedirect<{
  destinationId: ExistingDestination["id"];
}>) => Promise<WithRedirect<{}>> = ({ destinationId, redirect }) => {
  return axios
    .delete(`${ApiRoutes.DESTINATIONS}/${destinationId}`)
    .then((response: ApiResponse<{}>) => ({ redirect }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to delete destination.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getDestinationPublicKey: () => Promise<string> = () => {
  return axios
    .post(ApiRoutes.DESTINATION_PUBLIC_KEY)
    .then(
      (response: ApiResponse<{ public_key: string }>) =>
        response.data.data.public_key
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to generate public SSH key.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getDestinationServiceAccount: () => Promise<string> = () => {
  return axios
    .post(ApiRoutes.DESTINATION_SERVICE_ACCOUNT)
    .then(
      (response: ApiResponse<{ service_account_name: string }>) =>
        response.data.data.service_account_name
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to generate Service Account.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const DestinationsService = {
  getDestinations,
  testDestination,
  testExistingDestination,
  postDestination,
  transferDestination,
  patchDestination,
  deleteDestination,
  getDestinationPublicKey,
  getDestinationServiceAccount,
};
export default DestinationsService;
