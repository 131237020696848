import axios, { ApiRoutes, ApiResponse, ErrorResponse } from "../../axios";

import { TransferReportDay } from ".";
import Destination from "../destinations";

const getTransferReport: ({
  destinationId,
}: {
  destinationId: Destination["id"] | undefined;
}) => Promise<TransferReportDay[]> = ({ destinationId }) => {
  // now - (31 days in seconds)
  const [oneMonthAgoDateString] = new Date(Date.now() - 2678e6)
    .toISOString()
    .split("T");
  const [tomorrowDateString] = new Date(Date.now() + 8640e4)
    .toISOString()
    .split("T");

  return axios
    .get(ApiRoutes.REPORT_TRANSFERS, {
      params: {
        start: oneMonthAgoDateString,
        end: tomorrowDateString,
        destinations: destinationId || "",
      },
    })
    .then((response: ApiResponse<TransferReportDay[]>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch transfer report.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const TransferReportService = {
  getTransferReport,
};
export default TransferReportService;
