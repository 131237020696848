import React from "react";
import { ModelConfig } from "@prequel/react";
import {
  Table,
  TableRow,
  Badge,
  BadgeColor,
} from "@prequel-internal/react-components";
import { useNavigate } from "react-router-dom";

type ModelConfigsTableProps = {
  modelConfigs: ModelConfig[] | undefined;
};
const ModelConfigsTable = ({ modelConfigs = [] }: ModelConfigsTableProps) => {
  const navigate = useNavigate();

  const rows = [...modelConfigs].map(({ model_name, description }) => ({
    id: model_name,
    cells: [
      {
        value: <Badge color={BadgeColor.GRAY} text={model_name} />,
      },
      {
        value: (
          <>
            {description === "" ? (
              <span className="flex-grow text-gray-300 italic">undefined</span>
            ) : (
              description
            )}
          </>
        ),
      },
    ],
  }));

  const columns = ["Model Name", "Description"];

  const onRowClick = (row: TableRow) => navigate(`${row.id}`);

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={modelConfigs ? `${modelConfigs.length} models` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default ModelConfigsTable;
