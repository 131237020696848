import React, { useEffect } from "react";
import {
  checkTabIsActive,
  PageHeading,
  Tabs,
} from "@prequel-internal/react-components";
import { Navigate, NavLink, useRoutes } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  fetchModelConfigs,
  fetchProductConfigs,
  selectModelConfigs,
  selectProductConfigs,
} from "../../store/configs/configs.duck";
import ModelConfigsTable from "../ModelConfigsTable";
import ProductConfigsTable from "../ProductConfigsTable";
import ModelConfigPage from "../ModelConfigPage";

const ConfigurationsPage = () => {
  const tabs = [
    <NavLink
      key="Models"
      to="/configuration/models"
      className={checkTabIsActive}
      replace
      end
    >
      Models
    </NavLink>,
    <NavLink
      key="Products"
      to="/configuration/products"
      className={checkTabIsActive}
      replace
      end
    >
      Products
    </NavLink>,
  ];
  const dispatch = useTypedDispatch();
  const productConfigs = useTypedSelector(selectProductConfigs);
  const modelConfigs = useTypedSelector(selectModelConfigs);

  useEffect(() => {
    dispatch(fetchProductConfigs());
    dispatch(fetchModelConfigs());
  }, [dispatch]);

  const element = useRoutes([
    {
      path: "/",
      element: <Navigate to="/configuration/models" />,
    },
    {
      path: "products",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Configuration" />
          <Tabs tabs={tabs} />
          <ProductConfigsTable productConfigs={productConfigs} />
        </div>
      ),
    },
    {
      path: "models",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Configuration" />
          <Tabs tabs={tabs} />
          <ModelConfigsTable modelConfigs={modelConfigs} />
        </div>
      ),
    },
    {
      path: "models/:modelName",
      element: (
        <div className="max-w-4xl">
          <ModelConfigPage />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col pb-4">{element}</div>;
};

export default ConfigurationsPage;
