import React from "react";
import {
  Alert,
  Button,
  FormField,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchSSOLogin,
  selectSSOEnabled,
  selectSSOLogin,
} from "../../../store/auth/auth.duck";

import { FormState } from "..";

type SsoCardProps = {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const SsoCard = ({ setFormState, email, setEmail }: SsoCardProps) => {
  const dispatch = useTypedDispatch();

  const ssoEnabled = useTypedSelector(selectSSOEnabled);
  const ssoLogin = useTypedSelector(selectSSOLogin);

  const onLoginWithSSO = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(fetchSSOLogin(email));
  };

  return (
    <form onSubmit={onLoginWithSSO} className="space-y-4">
      <FormField
        id="email"
        key="email"
        type="email"
        placeholder="you@yourcompany.com"
        value={email}
        label="Email"
        onChangeHandler={setEmail}
        required
        autoFocus
      />

      {!ssoLogin && (
        <Button
          className="w-full"
          submit
          disabled={ssoEnabled?.state === "LOADING"}
          text={"Sign in with SSO"}
        />
      )}

      {ssoLogin?.state === "REDIRECTING" ||
        (ssoLogin?.state === "LOADING" && (
          <Button
            className="w-full"
            disabled
            text={
              <div className="flex space-x-2.5">
                <Spinner.Inline />
                <span>Sending to SSO</span>
              </div>
            }
          />
        ))}

      {ssoLogin?.state === "ERROR" && (
        <Alert>SSO is not enabled for this account.</Alert>
      )}

      <div className="text-sm flex justify-center text-gray-500 ">
        <a
          className="text-primary-600 cursor-pointer hover:underline"
          onClick={() => setFormState("NOTHING_SELECTED")}
        >
          Back to other options
        </a>
      </div>
    </form>
  );
};

export default SsoCard;
