import { addDays, formatDistanceToNow, parseJSON } from "date-fns";

const MAGIC_LINK_TTL_DAYS = 7;

export const getTimeRemaining = (dateString: string) => {
  const date = parseJSON(dateString);
  const expirationDate = addDays(date, MAGIC_LINK_TTL_DAYS);
  return formatDistanceToNow(expirationDate);
};

export const sortByCreatedAt = (linkA: MagicLink, linkB: MagicLink) => {
  const nullDate = new Date(0).toISOString(); // Create nullDate to replace null values with an early timestamp (epoch time)
  const dateA = parseJSON(linkA.created_at ?? nullDate);
  const dateB = parseJSON(linkB.created_at ?? nullDate);
  if (dateA > dateB) {
    return -1;
  }

  if (dateB > dateA) {
    return 1;
  }

  return 0;
};

export type FormMagicLink = Omit<
  MagicLink,
  "link" | "created_at" | "id" | "has_been_used"
>;
export default interface MagicLink {
  id: string;
  name: string;
  id_in_provider_system: string;
  host: string;
  link: string;
  created_at: string;
  has_been_used: boolean;
  bucket_name: string;
  vendor: string;
  bucket_vendor: string;
  bucket_access_id: string;
  products: string[];
}
