import React from "react";
import { ProductConfig } from "@prequel/react";
import { Badge, BadgeColor, Table } from "@prequel-internal/react-components";
import { useNavigate } from "react-router-dom";

type ProductConfigsTableProps = {
  productConfigs: ProductConfig[] | undefined;
};
const ProductConfigsTable = ({
  productConfigs = [],
}: ProductConfigsTableProps) => {
  const navigate = useNavigate();

  const rows = [...productConfigs].map(({ product_name, models }) => ({
    id: product_name,
    cells: [
      {
        value: product_name,
      },
      {
        value: (
          <>
            {models.length
              ? models.map((model) => (
                  <Badge
                    key={model}
                    className="mr-1"
                    text={model}
                    color={BadgeColor.GRAY}
                    onClick={() => navigate(`/configuration/models/${model}`)}
                  />
                ))
              : "No models defined"}
          </>
        ),
      },
    ],
  }));

  const columns = ["Product Name", "Models"];

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={productConfigs ? `${productConfigs.length} products` : ""}
    />
  );
};

export default ProductConfigsTable;
