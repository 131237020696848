import React, { useState } from "react";
import { Destination } from "@prequel/react";
import { FormField, Toggle } from "@prequel-internal/react-components";

import SSHPublicKey from "../SSHPublicKey";

type SSHTunnelFormProps = {
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean
  ) => void;
};
const SSHTunnelForm = ({
  destination,
  setDestinationField,
}: SSHTunnelFormProps) => {
  return (
    <>
      <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
      <div className="space-y-4">
        <Toggle
          label="Connect using SSH tunnel"
          enabled={destination.use_ssh_tunnel}
          setEnabled={(isChecked: boolean) =>
            setDestinationField("use_ssh_tunnel", isChecked)
          }
        />
        {destination.use_ssh_tunnel && (
          <>
            <SSHPublicKey
              setPublicKeyOnDestination={(publicKey: string) =>
                setDestinationField("ssh_public_key", publicKey)
              }
            />
            <FormField
              label="SSH Tunnel Host"
              id="ssh_tunnel_host"
              type="text"
              value={destination.ssh_tunnel_host}
              onChangeHandler={(value: string) => {
                setDestinationField("ssh_tunnel_host", value);
              }}
              required={destination.use_ssh_tunnel}
            />

            <FormField
              label="SSH Tunnel Port"
              id="ssh_tunnel_port"
              type="number"
              placeholder={destination.use_ssh_tunnel ? "22" : ""}
              value={destination.ssh_tunnel_port}
              onChangeHandler={(value: string) => {
                setDestinationField("ssh_tunnel_port", value);
              }}
              required={destination.use_ssh_tunnel}
            />

            <FormField
              label="SSH Tunnel Username"
              id="ssh_tunnel_username"
              type="text"
              value={destination.ssh_tunnel_username}
              onChangeHandler={(value: string) => {
                setDestinationField("ssh_tunnel_username", value);
              }}
              required={destination.use_ssh_tunnel}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SSHTunnelForm;
