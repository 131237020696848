import React from "react";
import { useParams } from "react-router-dom";

import { selectDestination } from "../../store/destinations/destinations.duck";
import { useTypedSelector } from "../../store";
import TestConnection from "../TestConnection";

const ConnectionDetails = () => {
  const { destinationId } = useParams<{ destinationId: string }>();
  const destination = useTypedSelector((state) =>
    selectDestination(state, destinationId)
  );

  if (!destination) {
    return <div>The destination could not be found.</div>;
  }

  return <TestConnection existingDestination={destination} />;
};

export default ConnectionDetails;
