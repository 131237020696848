import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDestinationVendors, DestinationVendor } from "@prequel/react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormField,
  Dropdown,
  DropdownListItem,
  Checkbox,
} from "@prequel-internal/react-components";

import VendorLogo from "../VendorLogo";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { DropdownListItemWithFields } from "../../store/destinations";
import { createMagicLink } from "../../store/magic_links/magic_links.duck";
import { FormMagicLink } from "../../store/magic_links";
import ConfirmDataDestinationModal from "../ConfirmDataDestinationModal";
import { selectProductConfigs } from "../../store/configs/configs.duck";

const MagicLinkForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const destinationVendors = useDestinationVendors(
    process.env.REACT_APP_API_SERVER
  );

  const [dropdownDestinations, setDropdownDestinations] =
    useState<DropdownListItem<string>[]>();
  const [selectedItem, setSelectedItem] = useState<DropdownListItemWithFields>({
    key: "",
    text: "",
    fields: [],
    docs: "",
    uses_service_account: false,
    uses_staging_bucket: false,
    supports_ssh_tunnel: false,
  });
  const [link, setLink] = useState<FormMagicLink>({
    name: "",
    id_in_provider_system: "",
    host: "",
    bucket_name: "",
    bucket_vendor: "",
    bucket_access_id: "",
    vendor: selectedItem.key,
    products: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [products, setProducts] = useState<string[]>();
  const [productsError, setProductsError] = useState<string>();

  const productConfigs = useTypedSelector(selectProductConfigs);

  const requiresHost = useMemo(() => {
    return !!selectedItem.fields.find(
      (f) => f.name === "host" && f.is_required
    );
  }, [selectedItem]);

  const usesObjectStorageToWrite = useMemo(() => {
    return !!selectedItem.fields.find(
      (f) => f.name === "bucket_name" && f.is_required
    );
  }, [selectedItem]);

  // these vendor-specific references are temporary - will be replaced by form built off magic links jsonschema endpoint
  const requiresBucketVendor = useMemo(
    () => link.vendor === "databricks" || link.vendor === "clickhouse",
    [link]
  );

  const requiresBucketAccessId = useMemo(
    () => link.vendor === "abs" || link.bucket_vendor === "abs",
    [link]
  );

  const updateSelectedProducts = (isEnabled: boolean, productName: string) => {
    let updatedProducts: string[] = [];
    if (isEnabled) {
      updatedProducts = [...selectedProducts, productName];
    } else {
      updatedProducts = selectedProducts.filter((p) => p !== productName);
    }
    setSelectedProducts(updatedProducts);
  };

  useEffect(() => {
    if (destinationVendors?.destinations && !dropdownDestinations) {
      const options = destinationVendors.destinations.map(
        (d: DestinationVendor) => {
          const icon = () => <VendorLogo logo_url={d.logo_url} />;
          return {
            ...d,
            key: d.vendor_name,
            text: d.display_name,
            icon,
            uses_service_account: false,
          };
        }
      );
      setDropdownDestinations(options);
      setSelectedItem(options[0]);
    }
  }, [destinationVendors]);

  useEffect(() => {
    if (productConfigs) {
      const opts = productConfigs.map(({ product_name }) => product_name);
      setProducts(opts);
      // pre-select the default product if it exists
      setSelectedProducts(opts.find((o) => o === "default") ? ["default"] : []);
    }
  }, [productConfigs]);

  useEffect(() => {
    setProductsError(undefined);
    setLink((oldLink) => ({
      ...oldLink,
      products: [...selectedProducts],
    }));
  }, [selectedProducts]);

  useEffect(() => {
    // Reset the bucket name on changes so it doesn't get passed in the destination
    setLink((oldLink) => ({
      ...oldLink,
      bucket_name: "",
      vendor: selectedItem.key,
    }));
  }, [selectedItem]);

  const confirm = () => {
    setShowModal(false);
    dispatch(
      createMagicLink({ link, redirect: () => navigate("/magic-links") })
    );
  };

  const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If there are products to select and we have not picked one, prevent creation
    if (products && selectedProducts.length == 0) {
      setProductsError("Must select at least one product");
      return;
    }

    setShowModal(true);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <ConfirmDataDestinationModal
        kind="GENERATE_LINK"
        show={showModal}
        setShow={setShowModal}
        dataDestination={link}
        onConfirm={confirm}
      />
      <Form
        className="space-y-4"
        onSubmit={onSubmit}
        submitButtonRef={buttonRef}
      >
        {dropdownDestinations && (
          <Dropdown
            label={"Destination type"}
            items={dropdownDestinations}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
        <FormField
          label="Name"
          id="name"
          type="text"
          subtext="Descriptive name for this Magic Link. This will only be visible internally and is only used as a reference."
          required
          onChangeHandler={(value: string) =>
            setLink((oldLink) => ({ ...oldLink, name: value }))
          }
        />
        <FormField
          label="ID in Provider System"
          id="id_in_provider_system"
          type="text"
          subtext="Customer ID by which the source data will be filtered. In other words, this is the ID of the target customer in your source database. An engineer or database administrator can provide the unique value for each destination."
          required
          onChangeHandler={(value: string) =>
            setLink((oldLink) => ({ ...oldLink, id_in_provider_system: value }))
          }
        />
        {requiresHost && (
          <FormField
            label="Host of Destination"
            id="host"
            type="text"
            subtext="Host address of the destination database. The owner of the destination (your customer) should provide this value."
            required
            onChangeHandler={(value: string) =>
              setLink((oldLink) => ({ ...oldLink, host: value }))
            }
          />
        )}
        {requiresBucketVendor && (
          <FormField
            label="Bucket Vendor"
            id="bucket_vendor"
            type="text"
            subtext="Vendor of storage bucket to use. The owner of the destination (your customer) should provide this value."
            required
            onChangeHandler={(value: string) =>
              setLink((oldLink) => ({ ...oldLink, bucket_vendor: value }))
            }
          />
        )}
        {usesObjectStorageToWrite && (
          <FormField
            label="Bucket Name"
            id="bucket_name"
            type="text"
            subtext="Name of storage bucket to use. The owner of the destination (your customer) should provide this value."
            required
            onChangeHandler={(value: string) =>
              setLink((oldLink) => ({ ...oldLink, bucket_name: value }))
            }
          />
        )}
        {requiresBucketAccessId && (
          <FormField
            label="Bucket Access ID"
            id="bucket_access_id"
            type="text"
            subtext="Azure Blob Storage Access ID. The owner of the destination (your customer) should provide this value."
            required
            onChangeHandler={(value: string) =>
              setLink((oldLink) => ({ ...oldLink, bucket_access_id: value }))
            }
          />
        )}
        {products && (
          <>
            <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
            <label className="block text-sm font-medium text-gray-700">
              Select what products the destination will receive
            </label>
            {products.map((p) => (
              <Checkbox
                key={p}
                id={p}
                label={p}
                checked={selectedProducts.includes(p)}
                setChecked={(isChecked: boolean) =>
                  updateSelectedProducts(isChecked, p)
                }
              />
            ))}
            {productsError && (
              <p className="mt-1 text-xs font-medium text-red-600">
                {productsError}
              </p>
            )}
          </>
        )}
        <div>
          <Button
            className="mt-6"
            submit
            text="Generate Link"
            type="primary"
            ref={buttonRef}
          />
        </div>
      </Form>
    </>
  );
};

export default MagicLinkForm;
