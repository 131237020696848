import axios, { ApiResponse, ApiRoutes, ErrorResponse } from "../../axios";
import { ModelConfig, ProductConfig } from "@prequel/react";

const getModelConfigs: () => Promise<ModelConfig[]> = () => {
  return axios
    .get(ApiRoutes.MODEL_CONFIGS)
    .then(
      (response: ApiResponse<{ prequel_configs: ModelConfig[] }>) =>
        response.data.data.prequel_configs
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch model configs.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getProductConfigs: () => Promise<ProductConfig[]> = () => {
  return axios
    .get(ApiRoutes.PRODUCT_CONFIGS)
    .then(
      (response: ApiResponse<{ product_configs: ProductConfig[] }>) =>
        response.data.data.product_configs
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch product configs.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const ConfigsService = {
  getModelConfigs,
  getProductConfigs,
};
export default ConfigsService;
