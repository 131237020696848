import React, { useEffect, useState } from "react";
import {
  Checkbox,
  RadioCardOption,
  RadioCards,
  SectionExpandable,
} from "@prequel-internal/react-components";
import { selectOrg } from "../../../store/org/org.duck";
import { useTypedSelector } from "../../../store";
import { getFrequencyLabel, getFrequencyOptions } from "../../../store/org";

type AdvancedOptionsProps = {
  frequency: number | undefined;
  setFrequency: React.Dispatch<React.SetStateAction<number>>;
  useDefaultFrequency: boolean;
  setUseDefaultFrequency: React.Dispatch<React.SetStateAction<boolean>>;
};
const AdvancedOptions = ({
  frequency,
  setFrequency,
  useDefaultFrequency,
  setUseDefaultFrequency,
}: AdvancedOptionsProps) => {
  const org = useTypedSelector(selectOrg);
  const [frequencyOptions, setFrequencyOptions] =
    useState<RadioCardOption<number>[]>();
  const [selectedOption, setSelectedOption] =
    useState<RadioCardOption<number>>();

  useEffect(() => {
    // When we load the org, set the frequency options
    if (org && !frequencyOptions) {
      setFrequencyOptions(
        getFrequencyOptions(org.fastest_allowed_frequency_minutes)
      );
    }
  }, [org]);

  useEffect(() => {
    // Set the frequency when we load the frequency options (and haven't sent the frequency yet)
    if (frequencyOptions && !selectedOption) {
      const findVal = frequency
        ? frequency
        : org?.fastest_allowed_frequency_minutes;
      const o = frequencyOptions.find(({ value }) => value === findVal);
      setSelectedOption(o);
    }
  }, [frequencyOptions]);

  useEffect(() => {
    // If useDefaultFrequency or selectedOption changes, set the frequency accordingly
    if (selectedOption) {
      useDefaultFrequency
        ? setFrequency(0)
        : setFrequency(selectedOption.value);
    }
  }, [useDefaultFrequency, selectedOption]);

  return (
    <SectionExpandable label="Advanced options">
      {/* Using separate label so checkbox can be underneath it */}
      <div className="flex items-center justify-between">
        <h2 className={"text-sm font-medium text-gray-900 mb-3"}>
          Sync Frequency
        </h2>
      </div>
      <Checkbox
        key="use_default_frequency"
        id="use_default_frequency"
        label={
          <span>
            Use default frequency
            {org ? (
              <span className="text-gray-400 font-normal">
                {" "}
                ({getFrequencyLabel(org.default_frequency_minutes)})
              </span>
            ) : (
              ""
            )}
          </span>
        }
        checked={useDefaultFrequency}
        setChecked={(isChecked: boolean) => {
          setUseDefaultFrequency(isChecked);
        }}
      />
      <RadioCards
        options={frequencyOptions ?? []}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        disabled={useDefaultFrequency}
      />
    </SectionExpandable>
  );
};

export default AdvancedOptions;
