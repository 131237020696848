import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDestinationVendors } from "@prequel/react";
import {
  StatusLight,
  Status,
  Table,
  TableRow,
} from "@prequel-internal/react-components";

import VendorLogo from "../VendorLogo";
import {
  getReadableLastSuccessfulSync,
  getReadableVendor,
  getVendorLogoUrl,
  sortByLastSuccessfulSync,
} from "../../store/destinations";
import Destination from "../../store/destinations";

const DestinationsTable = ({
  destinations,
}: {
  destinations?: Destination[];
}) => {
  const navigate = useNavigate();

  const destinationVendors = useDestinationVendors(
    process.env.REACT_APP_API_SERVER
  );

  // copy the array before sorting because the array is frozen
  const rows = [...(destinations || [])]
    .sort(sortByLastSuccessfulSync)
    .map((destination) => ({
      id: destination.id,
      cells: [
        {
          value: destination.name,
        },
        {
          value: (
            <div className="flex items-center">
              <VendorLogo
                logo_url={getVendorLogoUrl(
                  destination.vendor,
                  destinationVendors.destinations || []
                )}
              />
              {getReadableVendor(
                destination.vendor,
                destinationVendors.destinations || []
              )}
            </div>
          ),
        },
        {
          value: (
            <div className="text-gray-500">
              {getReadableLastSuccessfulSync(
                destination.last_successful_transfer_ended_at ?? undefined
              )}
            </div>
          ),
        },
        {
          value: (
            <div className="flex items-center text-gray-500">
              <StatusLight
                status={
                  destination.is_enabled ? Status.ACTIVE : Status.INACTIVE
                }
              />
              {destination.is_enabled ? "Enabled" : "Disabled"}
            </div>
          ),
        },
      ],
    }));

  const columns = [
    "Destination Name",
    "Vendor",
    "Last Successful Sync",
    "Status",
  ];

  const onRowClick = (row: TableRow) => navigate(`${row.id}`);

  return (
    <Table
      columns={columns}
      rows={rows || []}
      footer={rows ? `${rows.length} connectors` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default DestinationsTable;
