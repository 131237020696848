import React from "react";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";

import Layout from "./containers/Layout";
import ToastsFeature from "./containers/ToastsFeature";

import Authentication from "./containers/Authentication";
import OverviewPage from "./containers/OverviewPage";
import DestinationsPage from "./containers/DestinationsPage";
import MagicLinksPage from "./containers/MagicLinksPage";
import ConfigsPage from "./containers/ConfigurationsPage";

const App = () => {
  const element = useRoutes([
    {
      path: "/overview/*",
      element: <OverviewPage />,
    },
    {
      path: "/destinations/*",
      element: <DestinationsPage />,
    },
    { path: "/magic-links/*", element: <MagicLinksPage /> },
    { path: "/configuration/*", element: <ConfigsPage /> },
    { path: "*", element: <Navigate to="/overview" replace /> },
  ]);

  return <Layout>{element}</Layout>;
};

const AppWrapper = () => {
  return (
    <Router>
      <ToastsFeature />
      <Authentication>
        <App />
      </Authentication>
    </Router>
  );
};

export default AppWrapper;
