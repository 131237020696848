import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { ModelConfig, ProductConfig } from "@prequel/react";

import ConfigsService from "./configs.service";
import { createWorkerSaga, RootState } from "..";
import { AppError } from "../../axios";

type ConfigsState = {
  modelConfigs: ModelConfig[] | undefined;
  productConfigs: ProductConfig[] | undefined;
};
const initialState: ConfigsState = {
  modelConfigs: undefined,
  productConfigs: undefined,
};

const fetchModelConfigsReducer: CaseReducer<
  ConfigsState,
  PayloadAction<void>
> = (state) => state;

const fetchModelConfigsSuccessReducer: CaseReducer<
  ConfigsState,
  PayloadAction<ModelConfig[]>
> = (state, action) => {
  state.modelConfigs = action.payload;
};

const fetchModelConfigsFailureReducer: CaseReducer<
  ConfigsState,
  PayloadAction<AppError>
> = (state) => state;

const fetchProductConfigsReducer: CaseReducer<
  ConfigsState,
  PayloadAction<void>
> = (state) => state;

const fetchProductConfigsSuccessReducer: CaseReducer<
  ConfigsState,
  PayloadAction<ProductConfig[]>
> = (state, action) => {
  // Only update the product configs if there are any, otherwise keep as undefined because the org does not have any configs
  state.productConfigs = action.payload.length > 0 ? action.payload : undefined;
};

const fetchProductConfigsFailureReducer: CaseReducer<
  ConfigsState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchModelConfigs() {
  yield takeEvery(
    fetchModelConfigs.type,
    createWorkerSaga(
      fetchModelConfigs,
      fetchModelConfigsSuccess,
      fetchModelConfigsFailure,
      ConfigsService.getModelConfigs
    )
  );
}

function* watchFetchProductConfigs() {
  yield takeEvery(
    fetchProductConfigs.type,
    createWorkerSaga(
      fetchProductConfigs,
      fetchProductConfigsSuccess,
      fetchProductConfigsFailure,
      ConfigsService.getProductConfigs
    )
  );
}

const configsSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    fetchModelConfigs: fetchModelConfigsReducer,
    fetchModelConfigsSuccess: fetchModelConfigsSuccessReducer,
    fetchModelConfigsFailure: fetchModelConfigsFailureReducer,
    fetchProductConfigs: fetchProductConfigsReducer,
    fetchProductConfigsSuccess: fetchProductConfigsSuccessReducer,
    fetchProductConfigsFailure: fetchProductConfigsFailureReducer,
  },
});

export const {
  fetchModelConfigs,
  fetchModelConfigsSuccess,
  fetchModelConfigsFailure,
  fetchProductConfigs,
  fetchProductConfigsSuccess,
  fetchProductConfigsFailure,
} = configsSlice.actions;

export const selectModelConfigs = ({ configs }: RootState) =>
  configs.modelConfigs;
export const selectModelConfig = ({ configs }: RootState, modelName?: string) =>
  configs.modelConfigs?.find(({ model_name }) => model_name === modelName);
export const selectProductConfigs = ({ configs }: RootState) =>
  configs.productConfigs;

export function* configsSaga() {
  yield all([watchFetchModelConfigs(), watchFetchProductConfigs()]);
}
export default configsSlice.reducer;
