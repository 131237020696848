import React from "react";
import {
  Alert,
  Button,
  FormField,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedSelector } from "../../../store";
import { selectPasswordlessLogin } from "../../../store/auth/auth.duck";

import { FormState } from "..";

type PasswordlessSubmittedCardProps = {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const PasswordlessSubmittedCard = ({
  setFormState,
  email,
  setEmail,
}: PasswordlessSubmittedCardProps) => {
  const passwordlessLogin = useTypedSelector(selectPasswordlessLogin);

  return (
    <form className="space-y-4">
      <FormField
        id="email"
        key="email"
        type="email"
        placeholder="you@yourcompany.com"
        value={email}
        label="Email"
        onChangeHandler={setEmail}
        required
        disabled
        autoFocus
      />

      {passwordlessLogin?.state === "LOADING" && (
        <Button
          className="w-full"
          submit
          disabled
          text={
            <div className="flex space-x-1">
              <Spinner.Inline className="text-white" />
              <span>Sending link...</span>
            </div>
          }
        />
      )}

      {passwordlessLogin?.state === "ERROR" && (
        <>
          <Alert>SSO is enabled. Sign in with SSO instead.</Alert>
          <div className="text-sm flex justify-center text-gray-500 ">
            <a
              className="text-primary-600 cursor-pointer hover:underline"
              onClick={() => setFormState("NOTHING_SELECTED")}
            >
              Back to other options
            </a>
          </div>
        </>
      )}

      {passwordlessLogin?.state === "SUBMITTED" && (
        <Alert kind="SUCCESS">
          <span className="leading-relaxed">
            {
              "Check your email! We've sent you a link to sign in. You may now safely close this page."
            }
          </span>
        </Alert>
      )}
    </form>
  );
};

export default PasswordlessSubmittedCard;
