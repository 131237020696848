import React, { useState, useEffect } from "react";
import { ModelConfig } from "@prequel/react";
import { Checkbox } from "@prequel-internal/react-components";

import { useTypedSelector } from "../../../store";
import {
  selectModelConfigs,
  selectProductConfigs,
} from "../../../store/configs/configs.duck";

type ProductsAndModelsFormProps = {
  isEditing: boolean;
  productsError?: string;
  modelsError?: string;
  enabledModels: string[];
  setEnabledModels: React.Dispatch<React.SetStateAction<string[]>>;
  selectedProducts: string[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
  setAllFutureModels: React.Dispatch<React.SetStateAction<boolean>>;
  allFutureModels: boolean;
};
const ProductsAndModelsForm = ({
  isEditing,
  productsError,
  modelsError,
  enabledModels,
  setEnabledModels,
  selectedProducts,
  setSelectedProducts,
  setAllFutureModels,
  allFutureModels,
}: ProductsAndModelsFormProps) => {
  const [availableProducts, setAvailableProducts] = useState<string[]>();
  const [availableModels, setAvailableModels] = useState<ModelConfig[]>();

  const productConfigs = useTypedSelector(selectProductConfigs);
  const modelConfigs = useTypedSelector(selectModelConfigs);

  useEffect(() => {
    // if product configs change, set up product and selectedProduct state
    if (productConfigs) {
      const configNames = productConfigs.map(
        ({ product_name }) => product_name
      );
      setAvailableProducts(configNames);
      // pre-select the default product if it exists
      setSelectedProducts(
        configNames.find((c) => c === "default") ? ["default"] : []
      );
    }
  }, [productConfigs]);

  useEffect(() => {
    // only set available models with all models in the case there are no product configs
    if (modelConfigs && !productConfigs) {
      setAvailableModels(modelConfigs);
      // initially enable all models if not in edit mode, we expect enabled models to be set if we are editing
      if (!isEditing) {
        setEnabledModels(modelConfigs.map(({ model_name }) => model_name));
      }
    }
  }, [modelConfigs]);

  useEffect(() => {
    // if selectedProducts change, update the available models list
    if (productConfigs) {
      let modelNames: string[] = [];
      productConfigs?.map((cfg) => {
        // If the product is in the list, append all the models
        if (selectedProducts.includes(cfg.product_name)) {
          modelNames = [...modelNames, ...cfg.models];
        }
      });

      const models = modelConfigs?.filter(({ model_name }) =>
        modelNames.includes(model_name)
      );

      setAvailableModels(models);
    }
  }, [selectedProducts, modelConfigs]);

  useEffect(() => {
    // if availableModels changes, reset all models to enabled if we are not in edit mode
    // if editing, we expect enabled models to be set from the destination
    if (availableModels && !isEditing) {
      setEnabledModels(availableModels.map(({ model_name }) => model_name));
    }
  }, [availableModels]);

  const updateEnabledModels = (isEnabled: boolean, modelName: string) => {
    let updatedModels: string[] = [];
    if (isEnabled) {
      updatedModels = [...enabledModels, modelName];
    } else {
      updatedModels = enabledModels.filter((m) => m !== modelName);
    }

    setEnabledModels(updatedModels);
  };

  const updatedEnabledProducts = (isEnabled: boolean, productName: string) => {
    let updatedProducts: string[] = [];
    if (isEnabled) {
      updatedProducts = [...selectedProducts, productName];
    } else {
      updatedProducts = selectedProducts.filter((p) => p !== productName);
    }

    setSelectedProducts(updatedProducts);
  };

  return (
    <>
      <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
      <div className="space-y-4">
        {availableProducts && !isEditing && (
          <>
            <label
              className={`block text-sm font-medium ${
                isEditing ? "text-gray-400" : "text-gray-700"
              }`}
            >
              Select what products the destination will receive
            </label>
            {availableProducts.map((p) => (
              <Checkbox
                key={p}
                id={p}
                label={p}
                checked={selectedProducts.includes(p)}
                setChecked={(isChecked: boolean) =>
                  updatedEnabledProducts(isChecked, p)
                }
                disabled={isEditing}
              />
            ))}
          </>
        )}
        {productsError && !isEditing && (
          <p className="mt-1 text-xs font-medium text-red-600">
            {productsError}
          </p>
        )}
        {availableModels && availableModels.length > 0 && (
          <>
            <label className="block text-sm font-medium text-gray-700">
              Select what models the destination will receive
            </label>
            {availableModels?.map((config) => (
              <Checkbox
                key={config.model_name}
                id={config.model_name}
                label={config.model_name}
                description={config.description}
                checked={
                  enabledModels.includes(config.model_name) || allFutureModels
                }
                setChecked={(isChecked: boolean) =>
                  updateEnabledModels(isChecked, config.model_name)
                }
                disabled={allFutureModels}
              />
            ))}
            <Checkbox
              key={"all_models_present_future"}
              id={"all_models_present_future"}
              label={"All current and future models"}
              description={
                "Any model added to these products in the future will be synced"
              }
              checked={allFutureModels}
              setChecked={(isChecked: boolean) => setAllFutureModels(isChecked)}
            />
          </>
        )}
        {modelsError && (
          <p className="mt-1 text-xs font-medium text-red-600">{modelsError}</p>
        )}
      </div>
    </>
  );
};

export default ProductsAndModelsForm;
