import axios, { AxiosError } from "axios";
import { EnhancedStore } from "@reduxjs/toolkit";

export const ID_PLACEHOLDER = ":id";

let reduxStore: EnhancedStore;
export const injectStore = (store: EnhancedStore) => {
  reduxStore = store;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

instance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers["Environment"] = reduxStore.getState().auth.environment;
  }
  return config;
});

export enum ApiRoutes {
  AUTH_ENVIRONMENT = "/auth/environment",
  AUTH_STATUS = "/auth-status",
  DESTINATIONS = "/destinations",
  DESTINATION_PUBLIC_KEY = "/actions/generate-ssh-key",
  DESTINATION_SERVICE_ACCOUNT = "/actions/generate-service-account",
  TRANSFERS = "/transfers",
  LOG_OUT = "/logout",
  MAGIC_LINKS = "/magic-links",
  MODEL_CONFIGS = "/configs",
  ORGS = "/orgs",
  PRODUCT_CONFIGS = "/products",
  PUBLIC_GOOGLE_OAUTH_LOGIN = "/public/auth/google-oauth-login",
  PUBLIC_PASSWORDLESS_LOGIN = "/public/auth/passwordless-login",
  PUBLIC_RETRIEVE_TOKEN = "/public/auth/retrieve-token",
  PUBLIC_SSO_ENABLED = "/public/auth/sso-enabled",
  PUBLIC_SSO_LOGIN = "/public/auth/sso-login",
  REPORT_TRANSFERS = "/reports/transfers",
  TEST_DESTINATION = "/test-destination",
  TEST_EXISTING_DESTINATION = "/destinations/:id/test-connection",
  TRANSFER = "/transfer",
  USER = "/me",
}

// ApiResponse and ErrorResponse are what we receive in then/catch clauses
export type ApiResponse<T> = {
  data: {
    data: T;
  };
};
export type ErrorResponse = AxiosError<{ message: string }, any>;

// AppError is the error type we pass around the application
export type AppError = {
  error: {
    message: string;
    suppressGlobalNotification?: boolean;
    statusCode?: number;
  };
};

export default instance;
