import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery, put } from "redux-saga/effects";
import { PreparedDestination } from "@prequel/react";

import { AppError } from "../../axios";
import ExistingDestination, {
  DestinationTest,
  DestinationTestStatus,
  ExistingDestinationTestPayload,
} from ".";
import DestinationsService from "./destinations.service";
import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";

// Slice state
type DestinationsState = {
  destinations: ExistingDestination[] | undefined;
  destinationTest: DestinationTest;
  destinationPublicKey: string | undefined;
  destinationServiceAccount: string | undefined;
};
const initialState: DestinationsState = {
  destinations: undefined,
  destinationTest: { status: undefined },
  destinationPublicKey: undefined,
  destinationServiceAccount: undefined,
};

// Action Reducers (Case Reducers)
const fetchDestinationsReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => state;

const fetchDestinationsSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<ExistingDestination[]>
> = (state, action) => {
  state.destinations = action.payload;
};

const fetchDestinationsFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const testDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<PreparedDestination>
> = (state) => {
  state.destinationTest.status = "processing";
};

const testDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<DestinationTestStatus>
> = (state, action) => {
  state.destinationTest.status = action.payload;
};

const testDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const testExistingDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<ExistingDestinationTestPayload>
> = (state) => {
  state.destinationTest.status = "processing";
};

const testExistingDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<DestinationTestStatus>
> = (state, action) => {
  state.destinationTest.status = action.payload;
};

const testExistingDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const createDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{ destination: PreparedDestination }>>
> = (state) => state;

const createDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{ destination: ExistingDestination }>>
> = (state) => state;

const createDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const updateDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<
    WithRedirect<{
      destinationId: ExistingDestination["id"];
      destination: Partial<PreparedDestination>;
    }>
  >
> = (state) => state;

const updateDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<{ destination: ExistingDestination }>
> = (state, action) => {
  const updatedDestination = action.payload.destination;
  state.destinations = state.destinations?.map((d) =>
    d.id === updatedDestination.id ? { ...d, ...updatedDestination } : d
  );
};

const updateDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const deleteDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<
    WithRedirect<{
      destinationId: ExistingDestination["id"];
    }>
  >
> = (state) => state;

const deleteDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{}>>
> = (state) => state;

const deleteDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const resetDestinationTestReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => {
  state.destinationTest.status = undefined;
};

const fetchDestinationPublicKeyReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => state;

const fetchDestinationPublicKeySuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<string>
> = (state, action) => {
  state.destinationPublicKey = action.payload;
};

const fetchDestinationPublicKeyFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const fetchDestinationServiceAccountReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => state;

const fetchDestinationServiceAccountSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<string>
> = (state, action) => {
  state.destinationServiceAccount = action.payload;
};

const fetchDestinationServiceAccountFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchDestinations() {
  yield takeEvery(
    fetchDestinations.type,
    createWorkerSaga(
      fetchDestinations,
      fetchDestinationsSuccess,
      fetchDestinationsFailure,
      DestinationsService.getDestinations
    )
  );
}

function* watchTestDestination() {
  yield takeEvery(
    testDestination.type,
    createWorkerSaga(
      testDestination,
      testDestinationSuccess,
      testDestinationFailure,
      DestinationsService.testDestination
    )
  );
}

function* watchTestExistingDestination() {
  yield takeEvery(
    testExistingDestination.type,
    createWorkerSaga(
      testExistingDestination,
      testExistingDestinationSuccess,
      testExistingDestinationFailure,
      DestinationsService.testExistingDestination
    )
  );
}

function* watchCreateDestination() {
  yield takeEvery(
    createDestination.type,
    createWorkerSaga(
      createDestination,
      createDestinationSuccess,
      createDestinationFailure,
      DestinationsService.postDestination
    )
  );
}

function* watchUpdateDestination() {
  yield takeEvery(
    updateDestination.type,
    createWorkerSaga(
      updateDestination,
      updateDestinationSuccess,
      updateDestinationFailure,
      DestinationsService.patchDestination
    )
  );
}

function* watchDeleteDestination() {
  yield takeEvery(
    deleteDestination.type,
    createWorkerSaga(
      deleteDestination,
      deleteDestinationSuccess,
      deleteDestinationFailure,
      DestinationsService.deleteDestination
    )
  );
}

function* watchCreateDestinationSuccess() {
  yield takeEvery([createDestinationSuccess.type], function* () {
    yield put(fetchDestinations());
  });
  yield takeEvery(createDestinationSuccess.type, createRedirectSaga());
}

function* watchUpdateDestinationSuccess() {
  yield takeEvery(updateDestinationSuccess.type, function* () {
    yield put(fetchDestinations());
  });
  yield takeEvery(updateDestinationSuccess.type, createRedirectSaga());
}

function* watchDeleteDestinationSuccess() {
  yield takeEvery(deleteDestinationSuccess.type, function* () {
    yield put(fetchDestinations());
  });
  yield takeEvery(deleteDestinationSuccess.type, createRedirectSaga());
}

function* watchFetchDestinationPublicKey() {
  yield takeEvery(
    fetchDestinationPublicKey.type,
    createWorkerSaga(
      fetchDestinationPublicKey,
      fetchDestinationPublicKeySuccess,
      fetchDestinationPublicKeyFailure,
      DestinationsService.getDestinationPublicKey
    )
  );
}

function* watchFetchDestinationServiceAccount() {
  yield takeEvery(
    fetchDestinationServiceAccount.type,
    createWorkerSaga(
      fetchDestinationServiceAccount,
      fetchDestinationServiceAccountSuccess,
      fetchDestinationServiceAccountFailure,
      DestinationsService.getDestinationServiceAccount
    )
  );
}

const destinationsSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    fetchDestinations: fetchDestinationsReducer,
    fetchDestinationsSuccess: fetchDestinationsSuccessReducer,
    fetchDestinationsFailure: fetchDestinationsFailureReducer,
    testDestination: testDestinationReducer,
    testDestinationSuccess: testDestinationSuccessReducer,
    testDestinationFailure: testDestinationFailureReducer,
    testExistingDestination: testExistingDestinationReducer,
    testExistingDestinationSuccess: testExistingDestinationSuccessReducer,
    testExistingDestinationFailure: testExistingDestinationFailureReducer,
    resetDestinationTest: resetDestinationTestReducer,
    createDestination: createDestinationReducer,
    createDestinationSuccess: createDestinationSuccessReducer,
    createDestinationFailure: createDestinationFailureReducer,
    updateDestination: updateDestinationReducer,
    updateDestinationSuccess: updateDestinationSuccessReducer,
    updateDestinationFailure: updateDestinationFailureReducer,
    deleteDestination: deleteDestinationReducer,
    deleteDestinationSuccess: deleteDestinationSuccessReducer,
    deleteDestinationFailure: deleteDestinationFailureReducer,
    fetchDestinationPublicKey: fetchDestinationPublicKeyReducer,
    fetchDestinationPublicKeySuccess: fetchDestinationPublicKeySuccessReducer,
    fetchDestinationPublicKeyFailure: fetchDestinationPublicKeyFailureReducer,
    fetchDestinationServiceAccount: fetchDestinationServiceAccountReducer,
    fetchDestinationServiceAccountSuccess:
      fetchDestinationServiceAccountSuccessReducer,
    fetchDestinationServiceAccountFailure:
      fetchDestinationServiceAccountFailureReducer,
  },
});

export const {
  fetchDestinations,
  fetchDestinationsSuccess,
  fetchDestinationsFailure,
  testDestination,
  testDestinationSuccess,
  testDestinationFailure,
  testExistingDestination,
  testExistingDestinationSuccess,
  testExistingDestinationFailure,
  resetDestinationTest,
  createDestination,
  createDestinationSuccess,
  createDestinationFailure,
  updateDestination,
  updateDestinationSuccess,
  updateDestinationFailure,
  deleteDestination,
  deleteDestinationSuccess,
  deleteDestinationFailure,
  fetchDestinationPublicKey,
  fetchDestinationPublicKeySuccess,
  fetchDestinationPublicKeyFailure,
  fetchDestinationServiceAccount,
  fetchDestinationServiceAccountSuccess,
  fetchDestinationServiceAccountFailure,
} = destinationsSlice.actions;

export const selectDestinations = ({ destinations }: RootState) =>
  destinations.destinations;
export const selectDestination = (
  rootState: RootState,
  destinationId?: string
) => selectDestinations(rootState)?.find((d) => d.id === destinationId);
export const selectDestinationTest = ({ destinations }: RootState) =>
  destinations.destinationTest;
export const selectDestinationPublicKey = ({ destinations }: RootState) =>
  destinations.destinationPublicKey;
export const selectDestinationServiceAccount = ({ destinations }: RootState) =>
  destinations.destinationServiceAccount;

export function* destinationsSaga() {
  yield all([
    watchFetchDestinations(),
    watchTestDestination(),
    watchTestExistingDestination(),
    watchCreateDestination(),
    watchCreateDestinationSuccess(),
    watchUpdateDestination(),
    watchUpdateDestinationSuccess(),
    watchDeleteDestination(),
    watchDeleteDestinationSuccess(),
    watchFetchDestinationPublicKey(),
    watchFetchDestinationServiceAccount(),
  ]);
}
export default destinationsSlice.reducer;
