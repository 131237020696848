import React, { useState, useMemo } from "react";

import {
  Button,
  Modal,
  ConfirmModal,
  DescriptionList,
} from "@prequel-internal/react-components";
import { useDestinationVendors, VendorField } from "@prequel/react";
import { useParams, useNavigate } from "react-router-dom";

import VendorLogo from "../VendorLogo";
import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  selectDestination,
  deleteDestination,
} from "../../store/destinations/destinations.duck";
import { createTransfer } from "../../store/transfers/transfers.duck";
import {
  getReadableLastSuccessfulSync,
  getReadableModels,
} from "../../store/destinations";

const DestinationDetails = () => {
  const { destinationId } = useParams<{ destinationId: string }>();
  const destination = useTypedSelector((state) =>
    selectDestination(state, destinationId)
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();
  const destinationVendors = useDestinationVendors(
    process.env.REACT_APP_API_SERVER
  );

  // Filter the list of vendors to the specific vendor to be detailed
  const destinationVendor = destinationVendors.destinations?.find(
    (d) => d.vendor_name === destination?.vendor
  );

  // convert into map of name -> object
  const formFields: { [key: string]: VendorField } | undefined = useMemo(() => {
    return destinationVendor?.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [destinationVendor]);

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onForceRefresh = () => {
    setShowRefreshModal(true);
  };

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    if (destination) {
      dispatch(
        deleteDestination({
          destinationId: destination.id,
          redirect: () => navigate("/destinations"),
        })
      );
    }
  };

  const onConfirmForceRefresh = () => {
    setShowRefreshModal(false);
    if (destination) {
      dispatch(
        createTransfer({ destinationId: destination.id, fullRefresh: true })
      );
    }
  };

  return (
    <>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ConfirmModal
          title="Delete destination"
          message="Are you sure you want to delete this destination? This action cannot be undone."
          onConfirm={onConfirmDelete}
          confirmButtonText="Delete"
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
      <Modal open={showRefreshModal} onClose={() => setShowRefreshModal(false)}>
        <ConfirmModal
          title="Force full refresh"
          message="Are you sure you want to force a full data refresh for this destination? This will re-send all historical data and may take a while."
          onConfirm={onConfirmForceRefresh}
          confirmButtonText="Force full refresh"
          onCancel={() => setShowRefreshModal(false)}
        />
      </Modal>
      {/* Description list */}
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Status
          </h3>
        </div>
        <div className="mt-6">
          <DescriptionList>
            <DescriptionList.Item
              label="Last Successful Transfer"
              value={getReadableLastSuccessfulSync(
                destination?.last_successful_transfer_ended_at ?? undefined
              )}
            />
            <DescriptionList.Item
              label="Current Status"
              value={destination?.is_enabled ? "Enabled" : "Disabled"}
            />
          </DescriptionList>
        </div>
      </div>

      {/* Description list */}
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Details
          </h3>
        </div>
        <div className="mt-6">
          {formFields && destination && (
            <DescriptionList>
              {destinationVendor && (
                <DescriptionList.Item
                  label="Vendor"
                  value={
                    <div className="flex items-center">
                      <VendorLogo logo_url={destinationVendor.logo_url} />
                      {destinationVendor.display_name}
                    </div>
                  }
                />
              )}
              {"name" in formFields && (
                <DescriptionList.Item label="Name" value={destination.name} />
              )}

              <DescriptionList.Item
                label="ID in Provider System"
                value={destination.id_in_provider_system}
              />

              {"host" in formFields && (
                <DescriptionList.Item
                  label={formFields.host.label}
                  value={destination.host}
                />
              )}
              {"port" in formFields && (
                <DescriptionList.Item
                  label={formFields.port.label}
                  value={destination.port ? destination.port.toString() : ""}
                />
              )}
              {"database" in formFields && (
                <DescriptionList.Item
                  label={formFields.database.label}
                  value={destination.database}
                />
              )}
              {"schema" in formFields && (
                <DescriptionList.Item
                  label={formFields.schema.label}
                  value={destination.schema}
                />
              )}
              {"username" in formFields && (
                <DescriptionList.Item
                  label={formFields.username.label}
                  value={destination.username}
                />
              )}

              {"bucket_vendor" in formFields && (
                <DescriptionList.Item
                  label={formFields.bucket_vendor.label}
                  value={destination.bucket_vendor}
                />
              )}
              {"bucket_name" in formFields && (
                <DescriptionList.Item
                  label={formFields.bucket_name.label}
                  value={destination.bucket_name}
                />
              )}
              {"bucket_region" in formFields && (
                <DescriptionList.Item
                  label={formFields.bucket_region.label}
                  value={destination.bucket_region}
                />
              )}
              {"bucket_access_id" in formFields && (
                <DescriptionList.Item
                  label={formFields.bucket_access_id.label}
                  value={destination.bucket_access_id}
                />
              )}

              {"use_ssh_tunnel" in formFields && (
                <DescriptionList.Item
                  label="Uses SSH Tunnel"
                  value={destination.use_ssh_tunnel ? "Yes" : "No"}
                />
              )}

              <DescriptionList.Item
                label="Enabled models"
                value={getReadableModels(destination.enabled_models)}
              />
            </DescriptionList>
          )}
        </div>
      </div>

      <div className="mt-10 divide-y divide-red-300">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Danger Zone
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Actions in this section should be considered carefully.
          </p>
        </div>
        <div className="mt-6">
          <DescriptionList type="danger">
            {/* <DescriptionList.ButtonItem
              label="Edit destination"
              description="Edit destination fields. Destination vendor, host, and id_in_provider_sytem cannot be changed."
            >
              <Button
                text="Edit destination"
                type="danger"
                onClick={() => navigate(`/destinations/${destinationId}/edit`)}
              />
            </DescriptionList.ButtonItem> */}
            <DescriptionList.ButtonItem
              label="Delete destination"
              description="Once you delete a destination, the action cannot be undone."
            >
              <Button
                text="Delete destination"
                type="danger"
                onClick={onDelete}
              />
            </DescriptionList.ButtonItem>
            <DescriptionList.ButtonItem
              label="Force full refresh for destination"
              description="A full refresh will re-sync all available data, and may take a while."
            >
              <Button
                text="Force full refresh"
                type="danger"
                onClick={onForceRefresh}
              />
            </DescriptionList.ButtonItem>
          </DescriptionList>
        </div>
      </div>
    </>
  );
};

export default DestinationDetails;
