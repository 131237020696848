import React, { useState, useEffect } from "react";

type VendorLogoProps = {
  logo_url: string;
};
const VendorLogo = ({ logo_url }: VendorLogoProps) => {
  // eslint-disable-next-line
  const genericImageSrc = require("../../assets/logos/generic.svg").default;

  const [imageSrc, setImageSrc] = useState<string>(genericImageSrc);
  const onImageLoadError = () => setImageSrc(genericImageSrc);

  useEffect(() => {
    setImageSrc(logo_url);
  }, [logo_url]);

  return (
    <div key={imageSrc} className="h-5 w-5 inline-flex mr-2.5">
      <img src={imageSrc} alt="" onError={onImageLoadError} />
    </div>
  );
};

export default VendorLogo;
