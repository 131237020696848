import React, { useState } from "react";

import {
  useParams,
  useNavigate,
  useRoutes,
  NavLink,
  Navigate,
} from "react-router-dom";
import {
  Button,
  Modal,
  ConfirmModal,
  PageHeading,
  Spinner,
  Tabs,
  Toggle,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  selectDestination,
  updateDestination,
  deleteDestination,
} from "../../store/destinations/destinations.duck";
import {
  createTransfer,
  selectIsPostingTransfer,
} from "../../store/transfers/transfers.duck";
import TransfersList from "../TransfersList";

import DestinationDetails from "../DestinationDetails";
import OverviewChart from "../OverviewChart";
import ConnectionDetails from "../ConnectionDetails";

const DestinationPage = () => {
  const tabs = [
    <NavLink key="General" to="" className={checkTabIsActive} replace end>
      General
    </NavLink>,
    <NavLink
      key="Transfers"
      to="transfers"
      className={checkTabIsActive}
      replace
      end
    >
      Transfers
    </NavLink>,
    <NavLink
      key="Connection"
      to="connection"
      className={checkTabIsActive}
      replace
      end
    >
      Connection
    </NavLink>,
  ];
  const { destinationId } = useParams<{ destinationId: string }>();
  const destination = useTypedSelector((state) =>
    selectDestination(state, destinationId)
  );
  const isPostingTransfer = useTypedSelector(selectIsPostingTransfer);

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const element = useRoutes([
    {
      path: "/transfers",
      element: <TransfersList />,
    },
    {
      path: "/connection",
      element: <ConnectionDetails />,
    },
    {
      path: "/",
      element: <DestinationDetails />,
    },
  ]);

  if (!destination) {
    return <Navigate to="/destinations" />;
  }

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    dispatch(
      deleteDestination({
        destinationId: destination.id,
        redirect: () => navigate("/destinations"),
      })
    );
  };

  const onConfirmForceRefresh = () => {
    setShowRefreshModal(false);
    dispatch(
      createTransfer({ destinationId: destination.id, fullRefresh: true })
    );
  };

  const setEnabled = (isEnabled: boolean) => {
    dispatch(
      updateDestination({
        destinationId: destination.id,
        destination: { is_enabled: isEnabled },
        redirect: () => true, // do not need redirect
      })
    );
  };

  const onSyncNow = () => {
    dispatch(
      createTransfer({ destinationId: destination.id, fullRefresh: false })
    );
  };

  return (
    <>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ConfirmModal
          title="Delete destination"
          message="Are you sure you want to delete this destination? This action cannot be undone."
          onConfirm={onConfirmDelete}
          confirmButtonText="Delete"
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
      <Modal open={showRefreshModal} onClose={() => setShowRefreshModal(false)}>
        <ConfirmModal
          title="Force full refresh"
          message="Are you sure you want to force a full data refresh for this destination? This will re-send all historical data and may take a while."
          onConfirm={onConfirmForceRefresh}
          confirmButtonText="Force full refresh"
          onCancel={() => setShowRefreshModal(false)}
        />
      </Modal>
      <PageHeading title={destination.name} onGoBack={() => navigate(-1)}>
        <Button
          text={
            isPostingTransfer ? (
              <div className="flex">
                <Spinner.Inline className="text-emerald-600" /> Starting Sync
              </div>
            ) : (
              "Sync Now"
            )
          }
          type="tertiary"
          onClick={onSyncNow}
        />
        <Toggle
          label="Enabled"
          enabled={destination.is_enabled}
          setEnabled={setEnabled}
        />
      </PageHeading>
      <OverviewChart destinationId={destinationId} />
      <div className="flex-1">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-0 pb-16">
            <div className="pb-6">
              {/* Tabs */}
              <Tabs tabs={tabs} />
              {element}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DestinationPage;
