import React, { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  FormField,
  TextArea,
  PasswordDot,
} from "@prequel-internal/react-components";
import {
  prepareDestination,
  useDestinationVendors,
  VendorField,
  Destination,
  DestinationVendor,
  PreparedDestination,
} from "@prequel/react";

import { useTypedDispatch, useTypedSelector } from "../../store";
import BaseForm from "./BaseForm";

import {
  computeChangedFields,
  convertToDestination,
  getValidServiceAccountKey,
} from "../../store/destinations";
import {
  selectDestinationTest,
  createDestination,
  selectDestination,
  updateDestination,
} from "../../store/destinations/destinations.duck";
import TestConnection from "../TestConnection";
import ConfirmDataDestinationModal from "../ConfirmDataDestinationModal";
import ProductsAndModelsForm from "./ProductsAndModelsForm";
import SSHTunnelForm from "./SSHTunnelForm";
import ServiceAccount from "./ServiceAccount";
import { selectProductConfigs } from "../../store/configs/configs.duck";
import { DropdownListItemWithFields } from "../../store/destinations";
import VendorLogo from "../VendorLogo";
import AdvancedOptions from "./AdvancedOptions";
import {
  ALL_CURRENT_FUTURE_MODELS,
  isAllCurrentFutureModels,
} from "../../store/configs";

const DestinationForm = () => {
  const navigate = useNavigate();
  const { destinationId } = useParams<{ destinationId: string }>();
  const destinationToEdit = useTypedSelector((state) =>
    selectDestination(state, destinationId)
  );
  const isEditing = destinationToEdit !== undefined;
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useTypedDispatch();
  const destinationVendors = useDestinationVendors(
    process.env.REACT_APP_API_SERVER
  );
  const [showModal, setShowModal] = useState(false);
  const [dropdownDestinations, setDropdownDestinations] =
    useState<DropdownListItemWithFields[]>();
  const [selectedItem, setSelectedItem] = useState<DropdownListItemWithFields>({
    key: "",
    text: "",
    fields: [],
    docs: "",
    uses_staging_bucket: false,
    uses_service_account: false,
    supports_ssh_tunnel: false,
  });
  const [destination, setDestination] = useState<Destination>({
    name: "",
    schema: "",
    host: "",
    port: "",
    database: "",
    username: "",
    password: "",
    vendor: selectedItem.key,
    id_in_provider_system: "",
    bucket_name: "",
    bucket_region: "",
    bucket_access_id: "",
    bucket_secret_key: "",
    enabled_models: [],
    products: [],
    use_ssh_tunnel: false,
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
    frequency_minutes: "",
    ssh_public_key: "",
    is_enabled: true,
  });
  const [enabledModels, setEnabledModels] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [allFutureModels, setAllFutureModels] = useState<boolean>(true);
  const [productsError, setProductsError] = useState<string>();
  const [modelsError, setModelsError] = useState<string>();
  const [useDefaultFrequency, setUseDefaultFrequency] = useState<boolean>(true);
  const [frequency, setFrequency] = useState<number>(0);

  const destinationTest = useTypedSelector(selectDestinationTest);
  const productConfigs = useTypedSelector(selectProductConfigs);

  // convert into map of name -> object
  const formFields: { [key: string]: VendorField } = useMemo(() => {
    return selectedItem.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [selectedItem]);

  const preparedDestination: PreparedDestination = useMemo(
    () => prepareDestination(destination),
    [destination]
  );

  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!getValidServiceAccountKey(destination.service_account_key),
    [destination.service_account_key]
  );

  const setDestinationField = (
    key: string,
    value: string | string[] | boolean
  ) => {
    setDestination((oldDestination) => ({
      ...oldDestination,
      [key]: value,
    }));
  };

  // When frequency changes, set it on the Destination
  useEffect(() => {
    setDestinationField("frequency_minutes", frequency.toString());
  }, [frequency]);

  useEffect(() => {
    setDestinationField("vendor", selectedItem?.key);
  }, [selectedItem]);

  useEffect(() => {
    setProductsError(undefined);
  }, [selectedProducts]);

  useEffect(() => {
    setModelsError(undefined);
  }, [enabledModels]);

  useEffect(() => {
    if (destinationToEdit) {
      setDestination(convertToDestination(destinationToEdit));
      const dropdownItem = dropdownDestinations?.find(
        ({ key }) => key === destinationToEdit.vendor
      );
      dropdownItem && setSelectedItem(dropdownItem);
      if (destinationToEdit.frequency_minutes) {
        setFrequency(destinationToEdit.frequency_minutes);
        setUseDefaultFrequency(false);
      }
      setSelectedProducts(destinationToEdit.products || []);
      setEnabledModels(destinationToEdit.enabled_models || []);
      setAllFutureModels(
        isAllCurrentFutureModels(destinationToEdit.enabled_models || [])
      );
    }
  }, [destinationToEdit]);

  useEffect(() => {
    if (destinationVendors?.destinations && !dropdownDestinations) {
      const options = destinationVendors.destinations.map(
        (d: DestinationVendor) => {
          const icon = () => <VendorLogo logo_url={d.logo_url} />;
          return {
            ...d,
            key: d.vendor_name,
            text: d.display_name,
            icon,
          };
        }
      );
      setDropdownDestinations(options);

      const opt =
        options.find(({ key }) => key === destinationToEdit?.vendor) ??
        options[0];
      setSelectedItem(opt);
    }
  }, [destinationVendors]);

  const onConfirm = () => {
    setShowModal(false);

    dispatch(
      createDestination({
        destination: preparedDestination,
        redirect: () => navigate("/destinations"),
      })
    );
  };

  // Handle destination submission
  const onSave = () => {
    if (productConfigs && selectedProducts.length === 0) {
      setProductsError("This is a required field");
      return;
    }

    if (enabledModels.length === 0) {
      setModelsError("This is a required field");
      return;
    }

    preparedDestination.enabled_models = enabledModels;
    if (allFutureModels) {
      preparedDestination.enabled_models = ALL_CURRENT_FUTURE_MODELS;
    }

    if (isEditing) {
      const changed = computeChangedFields(
        destinationToEdit,
        preparedDestination
      );
      dispatch(
        updateDestination({
          destinationId: destinationToEdit.id,
          destination: changed,
          redirect: () => navigate("/destinations"),
        })
      );
    } else {
      // Can only set products on destination creation
      if (selectedProducts.length > 0) {
        preparedDestination.products = selectedProducts;
      }
      setShowModal(true);
    }
  };

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave();
  };

  return (
    <div className="pb-16">
      <ConfirmDataDestinationModal
        kind="ADD_DESTINATION"
        show={showModal}
        setShow={setShowModal}
        dataDestination={preparedDestination}
        onConfirm={onConfirm}
      />
      <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
        <div className="space-y-4">
          {dropdownDestinations && (
            <Dropdown
              label={"Destination type"}
              items={dropdownDestinations}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              disabled={isEditing}
            />
          )}
        </div>
        <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
        <div className="space-y-4">
          <BaseForm
            isEditing={isEditing}
            displayName={selectedItem.text}
            destination={destination}
            setDestinationField={setDestinationField}
            disabled={destinationTest?.status === "processing"}
          />
        </div>
        <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Enter the destination credentials
            </label>
            <div className="mt-1">
              <p className="mt-1 text-sm text-gray-500">
                {`Provide the details shared by the owner of the ${selectedItem.text} destination
            in the form below. For assistance, `}
                <a
                  href={selectedItem.docs}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  view our documentation on {selectedItem.text}.
                </a>
              </p>
            </div>
          </div>
          {"host" in formFields && (
            <FormField
              id="host"
              type="text"
              label={formFields.host.label}
              placeholder={formFields.host.placeholder}
              subtext={formFields.host.help}
              value={destination?.host}
              onChangeHandler={(value: string) => {
                setDestinationField("host", value);
              }}
              required={formFields.host.is_required}
              disabled={destinationTest?.status === "processing" || isEditing}
            />
          )}
          {"port" in formFields && (
            <FormField
              id="port"
              type="text"
              label={formFields.port.label}
              placeholder={formFields.port.placeholder}
              subtext={formFields.port.help}
              value={destination?.port}
              onChangeHandler={(value: string) => {
                setDestinationField("port", value);
              }}
              required={formFields.port.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"database" in formFields && (
            <FormField
              id="database"
              type="text"
              label={formFields.database.label}
              placeholder={formFields.database.placeholder}
              subtext={formFields.database.help}
              value={destination?.database}
              onChangeHandler={(value: string) => {
                setDestinationField("database", value);
              }}
              required={formFields.database.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"schema" in formFields && (
            <FormField
              id="schema"
              type="text"
              label={formFields.schema.label}
              placeholder={formFields.schema.placeholder}
              subtext={formFields.schema.help}
              value={destination?.schema}
              onChangeHandler={(value: string) => {
                setDestinationField("schema", value);
              }}
              required={formFields.schema.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"username" in formFields && (
            <FormField
              id="username"
              type="text"
              label={formFields.username.label}
              placeholder={formFields.username.placeholder}
              subtext={formFields.username.help}
              value={destination?.username}
              onChangeHandler={(value: string) => {
                setDestinationField("username", value);
              }}
              required={formFields.username.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"password" in formFields && (
            <FormField
              id="password"
              type="password"
              label={formFields.password.label}
              placeholder={
                isEditing
                  ? PasswordDot.repeat(20)
                  : formFields.password.placeholder
              }
              overwriteOnly={isEditing}
              subtext={formFields.password.help}
              value={destination?.password}
              onChangeHandler={(value: string) => {
                setDestinationField("password", value);
              }}
              required={formFields.password.is_required && !isEditing}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"service_account_key" in formFields && (
            <TextArea
              id="service_account_key"
              placeholder={
                isEditing
                  ? PasswordDot.repeat(184)
                  : formFields.service_account_key.placeholder
              }
              overwriteOnly={isEditing}
              subtext={formFields.service_account_key.help}
              value={destination?.service_account_key}
              onChangeHandler={(value: string) => {
                setDestinationField("service_account_key", value);
              }}
              invalid={!tokenIsValid}
              required={
                formFields.service_account_key.is_required && !isEditing
              }
              disabled={destinationTest?.status === "processing"}
            />
          )}
        </div>
        {selectedItem.uses_staging_bucket && (
          <div className="h-px w-full bg-gray-200"></div>
        )}
        <div className="space-y-4">
          {selectedItem.uses_staging_bucket && (
            <>
              <label className="block text-sm font-medium text-gray-700">
                Enter the staging bucket credentials
              </label>
              <div className="mt-1">
                <p className="mt-1 text-sm text-gray-500">
                  {
                    "For some destinations without built in data staging areas, a staging bucket must be provided for efficent data loading. For assistance, "
                  }
                  <a
                    href="https://docs.prequel.co/docs/s3-staging-bucket"
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    view our documentation on staging buckets.
                  </a>
                </p>
              </div>
            </>
          )}
          {"bucket_vendor" in formFields && (
            <FormField
              id="bucket_vendor"
              type="text"
              label={formFields.bucket_vendor.label}
              placeholder={formFields.bucket_vendor.placeholder}
              subtext={formFields.bucket_vendor.help}
              value={destination?.bucket_vendor}
              onChangeHandler={(value: string) => {
                setDestinationField("bucket_vendor", value);
              }}
              required={formFields.bucket_vendor.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"bucket_name" in formFields && (
            <FormField
              id="bucket_name"
              type="text"
              label={formFields.bucket_name.label}
              placeholder={formFields.bucket_name.placeholder}
              subtext={formFields.bucket_name.help}
              value={destination?.bucket_name}
              onChangeHandler={(value: string) => {
                setDestinationField("bucket_name", value);
              }}
              required={formFields.bucket_name.is_required}
              disabled={destinationTest?.status === "processing" || isEditing}
            />
          )}
          {"bucket_region" in formFields && (
            <FormField
              id="bucket_region"
              type="text"
              label={formFields.bucket_region.label}
              placeholder={formFields.bucket_region.placeholder}
              subtext={formFields.bucket_region.help}
              value={destination?.bucket_region}
              onChangeHandler={(value: string) => {
                setDestinationField("bucket_region", value);
              }}
              required={formFields.bucket_region.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"bucket_access_id" in formFields && (
            <FormField
              id="bucket_access_id"
              type="text"
              label={formFields.bucket_access_id.label}
              placeholder={formFields.bucket_access_id.placeholder}
              subtext={formFields.bucket_access_id.help}
              value={destination?.bucket_access_id}
              onChangeHandler={(value: string) => {
                setDestinationField("bucket_access_id", value);
              }}
              required={formFields.bucket_access_id.is_required}
              disabled={destinationTest?.status === "processing"}
            />
          )}
          {"bucket_secret_key" in formFields && (
            <FormField
              id="bucket_secret_key"
              type="password"
              label={formFields.bucket_secret_key.label}
              placeholder={
                isEditing
                  ? PasswordDot.repeat(40)
                  : formFields.bucket_secret_key.placeholder
              }
              overwriteOnly={isEditing}
              subtext={formFields.bucket_secret_key.help}
              value={destination?.bucket_secret_key}
              onChangeHandler={(value: string) => {
                setDestinationField("bucket_secret_key", value);
              }}
              required={formFields.bucket_secret_key.is_required && !isEditing}
              disabled={destinationTest?.status === "processing"}
            />
          )}
        </div>
        {selectedItem.supports_ssh_tunnel && (
          <SSHTunnelForm
            destination={destination}
            setDestinationField={setDestinationField}
          />
        )}
        {selectedItem.uses_service_account && <ServiceAccount />}
        <ProductsAndModelsForm
          isEditing={isEditing}
          enabledModels={enabledModels}
          setEnabledModels={setEnabledModels}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          allFutureModels={allFutureModels}
          setAllFutureModels={setAllFutureModels}
          productsError={productsError}
          modelsError={modelsError}
        />
        <AdvancedOptions
          frequency={preparedDestination.frequency_minutes}
          setFrequency={setFrequency}
          useDefaultFrequency={useDefaultFrequency}
          setUseDefaultFrequency={setUseDefaultFrequency}
        />
        <TestConnection
          beforeSubmitTest={validateForm}
          preparedDestination={preparedDestination}
          existingDestination={destinationToEdit}
        />
      </form>
      <div className="flex justify-end mt-8">
        {isEditing && (
          <Button
            className="mr-3"
            type="tertiary"
            onClick={() => navigate("..")}
            text="Cancel"
          />
        )}
        <Button
          type={
            !(destinationTest.status === "success") ? "tertiary" : "primary"
          }
          disabled={!(destinationTest.status === "success")}
          onClick={onSave}
          text="Save Destination"
        />
      </div>
    </div>
  );
};

export default DestinationForm;
