import React, { useState, useEffect } from "react";

import { Table, TableRow } from "@prequel-internal/react-components";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";

import { useTypedDispatch, useTypedSelector } from "../../store";
import { getDuration } from "../../store/transfers";
import {
  fetchTransfers,
  selectTransfers,
} from "../../store/transfers/transfers.duck";

const TransfersList = () => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const dispatch = useTypedDispatch();
  const { destinationId } = useParams<{ destinationId: string }>();
  const transfers = useTypedSelector(
    selectTransfers(destinationId || ""),
    shallowEqual // required if using `transfers` in a dependency array, as this selector returns a new array each render
  );

  useEffect(() => {
    dispatch(fetchTransfers({ destinationId: destinationId || "" }));
  }, [dispatch, destinationId]);

  useEffect(() => {
    const transferRows = transfers?.map((transfer) => ({
      id: transfer.id,
      cells: [
        {
          value: (
            <span className="truncate font-mono text-xs text-gray-600 bg-gray-100 px-2 py-0.5 rounded-sm">
              {format(
                new Date(transfer.submitted_at),
                "yyyy-MM-dd HH:mm:ss.SSS z"
              )}
            </span>
          ),
        },
        {
          value: (
            <span className="truncate font-mono text-xs text-gray-600 bg-gray-100 px-2 py-0.5 rounded-sm">
              {transfer.status}
            </span>
          ),
        },
        {
          value: (
            <div className="flex text-xs max-w-sm">
              <div className="whitespace-pre-line	">
                {transfer.rows_transferred.toLocaleString()}
              </div>
            </div>
          ),
        },
        {
          value: (
            <div className="flex text-xs max-w-sm">
              <div className="whitespace-pre-line	">{getDuration(transfer)}</div>
            </div>
          ),
        },
        {
          value: (
            <div className="flex text-xs">
              <div className="whitespace-pre-line">{transfer.log}</div>
            </div>
          ),
        },
      ],
    }));
    transferRows && setRows(transferRows);
  }, [transfers]);

  const columns = [
    "Submitted At",
    "Status",
    "Rows Transferred",
    "Time Elapsed",
    "Details",
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={`${rows.length} most recent transfers`}
    />
  );
};

export default TransfersList;
