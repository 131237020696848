import React, { useEffect } from "react";

import { useRoutes, useNavigate, NavLink } from "react-router-dom";
import {
  Button,
  PageHeading,
  Tabs,
  Spinner,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import DestinationsTable from "../DestinationsTable";
import DestinationForm from "../DestinationForm";
import DestinationPage from "../DestinationPage";

import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  fetchDestinations,
  selectDestinations,
} from "../../store/destinations/destinations.duck";
import {
  fetchModelConfigs,
  fetchProductConfigs,
} from "../../store/configs/configs.duck";

const DestinationsPage = () => {
  const navigate = useNavigate();
  const tabs = [
    <NavLink key="Summary" to="#" className={checkTabIsActive}>
      Summary
    </NavLink>,
  ];
  const dispatch = useTypedDispatch();
  const destinations = useTypedSelector(selectDestinations);

  useEffect(() => {
    dispatch(fetchDestinations());
    dispatch(fetchModelConfigs());
    dispatch(fetchProductConfigs());
  }, [dispatch]);

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Destinations">
            <Button
              text="Add Destination"
              onClick={() => navigate("new")}
              type="secondary"
            />
          </PageHeading>
          <Tabs tabs={tabs} />

          <DestinationsTable destinations={destinations} />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg">
          <PageHeading
            title="Add Destination"
            onGoBack={() => navigate("..")}
          />
          <DestinationForm />
        </div>
      ),
    },
    {
      path: ":destinationId/edit",
      element: (
        <div className="max-w-lg">
          <PageHeading
            title="Edit Destination"
            onGoBack={() => navigate("..")}
          />
          <DestinationForm />
        </div>
      ),
    },
    {
      path: ":destinationId/*",
      element: (
        <div className="max-w-4xl">
          {destinations ? <DestinationPage /> : <Spinner />}
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col pb-4">{element}</div>;
};

export default DestinationsPage;
